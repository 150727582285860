// @refresh reload
import { mount, StartClient } from "@solidjs/start/client";
import * as Sentry from "@sentry/browser";
import { config, isDevelopment, isProduction } from "#root/config";
import { useHttp } from "#root/domain/http";

Sentry.init({
  dsn: "https://eefed999b4364dc8b859d5216ad1cea5@bugs.llcoolserver.dev/2",
  tracesSampleRate: 0.1,
  environment: config.ENV,
  enabled: isProduction,
  integrations: [Sentry.browserTracingIntegration()],
  tracePropagationTargets: ["localhost:3000", /^https:\/\/asklive\.co\//],
  release: config.SENTRY_RELEASE,
});

window.addEventListener("error", (err) => {
  Sentry.captureException(err);
});

const csrfToken: string = document
  .querySelector('meta[name="x-csrf"]')
  ?.getAttribute("content")!;

useHttp().setCsrfToken(csrfToken);

window.createLemonSqueezy();
LemonSqueezy.Setup({
  eventHandler: (event) => {
    if (event.event) {
      console.debug(
        "[LS]",
        event.event,
        isDevelopment ? event.data : "[REDACTED]",
      );

      window.dispatchEvent(
        new CustomEvent(`ls:${event.event}`, { detail: event.data }),
      );
    }
  },
});

mount(() => <StartClient />, document.getElementById("app")!);
