import { ErrorBoundary } from "solid-js";
import { MetaProvider } from "@solidjs/meta";
import { Router } from "@solidjs/router";
import { FileRoutes } from "@solidjs/start/router";
import { getRequestEvent, isServer } from "solid-js/web";
import { Suspense } from "solid-js";
import * as Sentry from "@sentry/browser";

import "#style/styles.css";
import "#root/style/app.css";
import "#root/style/fonts.css";

import { TranslationProvider } from "#root/domain/i18n";
import { QueryProvider } from "#root/module/query";
import { ErrorPage } from "#root/components/ErrorPage";
import { SupportedLocale } from "./locale";

export default function App() {
  const lang = isServer
    ? (getRequestEvent()!.locals.lang as SupportedLocale)
    : (document
        .querySelector("html")
        ?.getAttribute("lang")! as SupportedLocale);

  return (
    <TranslationProvider locale={lang}>
      <QueryProvider>
        <Router
          base={lang}
          root={(props) => (
            <MetaProvider>
              <Suspense>
                <ErrorBoundary
                  fallback={(e) => {
                    console.error(e);
                    Sentry.captureException(e);
                    return <ErrorPage shouldLogError code={500} />;
                  }}
                >
                  {props.children}
                </ErrorBoundary>
              </Suspense>
            </MetaProvider>
          )}
        >
          <FileRoutes />
        </Router>
      </QueryProvider>
    </TranslationProvider>
  );
}
