import { QueryClient, QueryClientProvider } from "@tanstack/solid-query";
import { HTTPError } from "ky";
import { ParentProps, Show } from "solid-js";
import { SolidQueryDevtools } from "@tanstack/solid-query-devtools";

import { isDevelopment } from "#root/config";

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: 0,
    },
    queries: {
      retry: (failureCount: number, error: unknown) => {
        if (error instanceof HTTPError) {
          switch (error.response.status) {
            case 401:
              return false;
            case 422:
              return false;
            default:
              return failureCount < 2;
          }
        }
        return false;
      },
    },
  },
});

export const QueryProvider = (props: ParentProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      {/* <Show when={isDevelopment}> */}
      {/*   <SolidQueryDevtools /> */}
      {/* </Show> */}
      {props.children}
    </QueryClientProvider>
  );
};
